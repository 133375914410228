import React from 'react';
import './App.css';
import { FaGithub, FaLinkedin, FaDownload,FaEnvelope } from 'react-icons/fa';

const App = () => {
  return (
    <div className="App">
      {/* Adding Multiple Blob Elements */}
      <div className="blob1 small-blob"></div>
      <div className="blob2 small-blob"></div>
      <div className="blob3 small-blob"></div>
      <div className="blob4 small-blob"></div>
      <div className="blob5 small-blob"></div>
      <div className="blob6 small-blob"></div>
      <div className="blob7 small-blob"></div>

      {/* Welcome Section */}
      <div className="header-section small-header">
        <img src="/profile.jpg" alt="Profile" className="profile-picture" />
        <h2>WELCOME</h2>
        <p className="welcome-text">
          Hi, I'm Udore Barwise. I am an IT Consultant with 4 years of experience and graduating with a bachelor's in Information Technology in 2024. I specialize in web technologies, with strong proficiency in CSS, JavaScript, and frameworks like ReactJS. I excel at developing and managing user-friendly websites and web applications, leveraging responsive design to create visually appealing and highly functional digital experiences. I am passionate about applying my technical expertise, creative problem-solving skills, and love for web development to transition into a Web Developer role.
        </p>
        <div className="social-links">
          <a href="https://github.com/Kalifornium1337" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href="https://www.linkedin.com/in/udore-barwise/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
            </a>
            <a href="mailto:udorebarwise@protonmail.com" target="_blank" rel="noopener noreferrer">
            <FaEnvelope />
          </a>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="main-content">
        <div className="container">
          {/* Experience Section */}
          <div className="card experience-section side-card">
            <h3 className="card-header">Experience</h3>
            <div className="card-content">
              <ul>
                <li>Jan 2021 - Current: IT Consultant at Bwise Financial Solutions</li>
                <li>Feb 2022 - Jul 2022: Desktop Technician at The IT Manager</li>
                <li>Apr 2022 - Dec 2023: Self-Employed</li>
              </ul>
              {/* Download CV Section */}
              <div className="download-cv">
                <a href="/Udore_Barwise_CV.pdf" download>
                  <FaDownload /> Download My CV
                </a>
              </div>
            </div>
          </div>

          {/* About Section */}
          <div className="card about-section center-card">
            <h3 className="card-header">About Me</h3>
            <div className="card-content">
              <h4>My primary tools of choice include:</h4>
              <ul>
                <li>JavaScript</li>
                <li>React</li>
                <li>HTML/CSS</li>
                <li>PHP</li>
                <li>ASP.NET</li>
                <li>C#</li>
                <li>.NET Frameworks</li>
                <li>SQL & MySQL</li>
              </ul>
              <p>Beyond coding, I'm passionate about chess and nature</p>
            </div>
          </div>

          {/* Education Section */}
          <div className="card education-section side-card">
            <h3 className="card-header">Education</h3>
            <div className="card-content">
              <ul>
                <li>Bachelor of Information Technology Graduated 2024 at Belgium Campus.</li>
                <li>Matriculated in 2020 at Die Hoërskool Menlopark</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Wave Animation */}
      <div className="wave"></div>
    </div>
  );
}

export default App;
